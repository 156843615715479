<script setup lang="ts">
const emit = defineEmits<{
  (event: 'close', value: any): void
}>()

const { t, locale } = useI18n({ useScope: 'local' })
const app = inject<AppGuide.ApplicationFull | AppGuide.Application>('application')

const { track } = useAnalytic()

const step = ref(0)
provide('step', step)
provide('stepMax', 3)

const form = reactive({
  sent_by: '',
  email: '',
  second_email: '',
  phone: '',
  second_phone: '',
  display_name: '',
  message: '',
  language: locale.value,
})

function handle_goto(to: number) {
  track('goto-step-share', {
    uid: app?.uid,
    from: step.value,
    to,
  })
  step.value = to
}

function handle_next(to: number) {
  track('next-step-share', {
    uid: app?.uid,
    from: step.value,
    to,
  })

  step.value = to
}

const loading = ref(false)
const error = ref('')
async function handle_submit() {
  loading.value = true
  error.value = ''

  const share_data: any = {
    applicationId: app?.id || 0,
    display_name: form.display_name,
    description: form.message,
    language: form.language,
  }

  if (form.sent_by === 'email') {
    share_data.email = form.email
    share_data.sent_by_email = 1

    if (form.second_email)
      share_data.second_email = form.second_email
  }

  if (form.sent_by === 'sms') {
    share_data.phone = form.phone
    share_data.sent_by_sms = 1

    if (form.second_phone)
      share_data.second_phone = form.second_phone
  }

  try {
    const response = await sendShare(share_data)
    track('submit-share', {
      uid: app?.uid,
    })
    step.value = 3
  }

  catch (e: any) {
    if (e?.statusCode === 400 && form.sent_by === 'email')
      error.value = t('error.email-invalid')

    else if (e?.statusCode === 400 && form.sent_by === 'sms')
      error.value = t('error.phone-invalid')

    else if (e?.statusCode === 404)
      error.value = t('error.application-not-found')

    else
      error.value = t('error.unknown')
  }

  finally {
    loading.value = false
  }
}

function handle_close(source?: string) {
  if (loading.value)
    return

  emit('close', {
    source,
    step: step.value,
  })
}
</script>

<template>
  <div data-x-feature="pro_share" class="fixed inset-0 flex items-center justify-center z-modal ph-no-capture">
    <div class="absolute inset-0 flex items-center justify-center md:inset-4">
      <div class="max-w-3xl md:rounded-xl relative z-10 w-full shadow-modal h-full md:max-h-[42.125rem] bg-gray-50 overflow-x-auto">
        <div v-if="loading" class="absolute inset-0 z-50 flex items-center justify-center bg-violet-900/50">
          <IconSpinner class="text-white" />
        </div>

        <div class="flex flex-col h-full p-4 md:p-8 md:pb-0">
          <div v-show="step !== 3" class="relative flex pb-8 md:justify-center">
            <h1 class="flex items-center text-xl font-bold font-display">
              <IconNavigationSend class="w-6 h-6 mr-2" />
              <span>{{ t('title') }}</span>
            </h1>

            <button
              data-x-action="close"
              data-x-name="x_button"
              aria-label="close"
              class="absolute top-0 right-0 text-gray-400"
              @click="handle_close('cross-button')"
            >
              <IconAttributesBooleanCross class="w-8 h-8" />
            </button>
          </div>

          <FeaturesSharesStepSelectMethod
            v-show="step === 0"
            v-model:sent-by="form.sent_by"
            v-model:email="form.email"
            v-model:secondEmail="form.second_email"
            v-model:phone="form.phone"
            v-model:secondPhone="form.second_phone"
            data-x-section="select-method"
            @next="handle_next(1)"
          />
          <FeaturesSharesStepDisplayName
            v-show="step === 1"
            v-model:display-name="form.display_name"
            data-x-section="display-name"
            @goto="handle_goto"
            @next="handle_next(2)"
          />
          <FeaturesSharesStepMessage
            v-show="step === 2"
            v-model:message="form.message"
            v-model:language="form.language"
            data-x-section="message"
            :loading="loading"
            @goto="handle_goto"
            @send="handle_submit"
          >
            <FormError v-if="error">
              {{ error }}
            </FormError>
          </FeaturesSharesStepMessage>
          <FeaturesSharesStepComplet
            v-show="step === 3"
            data-x-section="complet"
            :sent-by="form.sent_by"
            :email="form.email"
            :phone="form.phone"
            @close="handle_close('complet-button')"
          />
        </div>
      </div>
      <div
        x-click
        data-x-action="close"
        data-x-name="backdrop"
        tabindex="0"
        class="fixed inset-0 z-0 hidden md:block bg-violet-800/90"
        @click="handle_close('overlay')"
      />
    </div>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Send To",
    "error": {
      "email-invalid": "The email entered is invalid.",
      "phone-invalid": "The phone number entered is invalid.",
      "application-not-found": "The app you are trying to share is currently unavailable. If the error persists, please contact support.",
      "unknown": "An unknown error occurred. Please try again later."
    }
  },
  "fr": {
    "title": "Envoyer à",
    "error": {
      "email-invalid": "Le courriel entré est invalide.",
      "phone-invalid": "Le numéro de téléphone entré est invalide.",
      "application-not-found": "L'application que vous essayez de partager n'est pas disponible pour le moment. Si l'erreur persiste, veuillez contacter le support.",
      "unknown": "Une erreur inconnue est survenue. Veuillez réessayer plus tard."
    }
  }
}
</i18n>
