<script setup lang="ts">
const props = defineProps<{
  email: string
  secondEmail: string
  valide: boolean | null
}>()

const emit = defineEmits<{
  'update:email': [value: string]
  'update:secondEmail': [value: string]
  'update:valide': [isValide: boolean]
}>()

const { t } = useI18n({ useScope: 'local' })

const email = useVModel(props, 'email', emit)
const emailErrorMessage = ref<string | null>(null)

const secondEmail = useVModel(props, 'secondEmail', emit)
const secondEmailErrorMessage = ref<string | null>(null)

const addOneMore = ref(false)
function handleRemoveSecondEmail() {
  addOneMore.value = false
  secondEmail.value = ''
  secondEmailErrorMessage.value = null
}

const schema = zod.object({
  email: zod
    .string()
    .email({ message: t('email.error.email') }),
  secondEmail: zod
    .string()
    .email({ message: t('secondEmail.error.email') })
    .optional()
    .or(zod.literal('')),
})

const fieldBlured = ref(false)
const secondFieldBlured = ref(false)
const valide = useVModel(props, 'valide', emit)

watch([email, secondEmail], ([email, secondEmail]) => {
  emailErrorMessage.value = null
  secondEmailErrorMessage.value = null

  try {
    schema.parse({ email, secondEmail })
    fieldBlured.value = true
    valide.value = true
  }

  catch (err) {
    valide.value = false

    if (err instanceof zod.ZodError) {
      for (const issue of err.issues) {
        if (issue.path[0] === 'email')
          emailErrorMessage.value = issue.message

        if (issue.path[0] === 'secondEmail')
          secondEmailErrorMessage.value = issue.message
      }
    }

    else { console.error(err) }
  }
})
</script>

<template>
  <div>
    <div class="field-input">
      <label for="recipient-email" class="text-center">
        {{ t('email.label') }}
      </label>
      <input
        id="recipient-email"
        v-model="email"
        data-x-name="email"
        name="recipient-email"
        type="email"
        :placeholder="t('email.placeholder')"
        required
        class="text-input"
        @blur="fieldBlured = true"
      >
      <p v-if="fieldBlured && emailErrorMessage" class="error-message">
        <IconAttributesBooleanCross class="w-4 h-4 ml-4 mr-1" />
        <span>{{ emailErrorMessage }}</span>
      </p>
    </div>

    <div v-if="addOneMore" class="mt-4 field-input">
      <div class="flex gap-2">
        <input
          id="recipient-second-email"
          v-model="secondEmail"
          data-x-name="second-email"
          name="recipient-second-email"
          aria-label="second email"
          type="email"
          :placeholder="t('secondEmail.placeholder')"
          required
          class="flex-grow flex-shrink text-input"
          @blur="secondFieldBlured = true"
        >
        <button
          type="button"
          data-x-action="remove"
          data-x-name="second-email"
          aria-label="remove second email"
          class="w-[3.625rem] h-[3.625rem] flex items-center justify-center flex-none text-gray-500 border border-gray-300 rounded-xl transition-colors hover:border-blue-500 hover:bg-blue-500 hover:text-white"
          @click="handleRemoveSecondEmail"
        >
          <IconNavigationTrash class="w-6 h-6" />
        </button>
      </div>
      <p v-if="secondFieldBlured && secondEmailErrorMessage" class="error-message">
        <IconAttributesBooleanCross class="w-4 h-4 ml-4 mr-1" />
        <span>{{ secondEmailErrorMessage }}</span>
      </p>
    </div>
    <button
      v-else
      data-x-action="add"
      data-x-name="second-email"
      class="w-full mt-4 font-bold text-center text-blue-500 transition-colors bg-blue-200 border border-blue-300 border-dashed rounded-full h-11 hover:border-blue-500 hover:bg-blue-500 hover:text-white"
      @click="addOneMore = true"
    >
      {{ t('add-one-more') }}
    </button>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "email": {
      "label": "Recipient",
      "placeholder": "Recipient email",
      "error": {
        "email": "Must be a valid email"
      }
    },
    "secondEmail": {
      "placeholder": "Second recipient email",
      "error": {
        "email": "Must be a valid email"
      }
    },
    "add-one-more": "Add a recipient"
  },
  "fr": {
    "email": {
      "label": "Destinataire",
      "placeholder": "Courriel du destinataire",
      "error": {
        "email": "Doit être une adresse courriel valide"
      }
    },
    "secondEmail": {
      "placeholder": "Courriel du second destinataire",
      "error": {
        "email": "Doit être une adresse courriel valide"
      }
    },
    "add-one-more": "Ajouter un destinataire"
  }
}
</i18n>
