<script setup lang="ts">
import type { Ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const props = defineProps<{
  language: string
  message: string
  loading?: boolean
}>()

const emit = defineEmits<{
  (event: 'update:language', value: string): void
  (event: 'update:message', value: string): void
  (event: 'send'): void
  (event: 'goto', value: number): void
}>()

const app = inject<Ref<AppGuide.ApplicationFull | AppGuide.Application>>('application')
const { t } = useI18n({ useScope: 'local' })
const { track } = useAnalytic()

const DEFAULT_MESSAGE = {
  en: 'As discussed, please find this app to help you with your health goals. I invite you to download it on your mobile device using the black button below. You can also find more information about this app on AppGuide, by clicking the white button. Have a nice day!',
  fr: 'Tel que discuté, je vous recommande cette appli pour vous aider à atteindre vos objectifs de santé. Je vous invite à la télécharger sur votre appareil mobile en utilisant le bouton noir ci-dessous. Vous trouverez plus d\'informations sur cette appli sur AppGuide, en cliquant sur le bouton blanc. Passez une bonne journée!',
}

const language = useVModel(props, 'language', emit)
const message = useVModel(props, 'message', emit)

watch(language, (lang) => {
  message.value = DEFAULT_MESSAGE[lang]
  track('select-language-share', {
    uid: app?.value?.uid,
    language: lang,
  })
}, { immediate: true })
</script>

<template>
  <FeaturesSharesStep @goto="emit('goto', $event)">
    <RadioGroup v-model="language" class="flex flex-col">
      <RadioGroupLabel class="mb-4 text-xl font-bold text-center">
        {{ t('fields.languages.label') }}
      </RadioGroupLabel>

      <ul class="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <RadioGroupOption v-slot="{ checked }" as="template" value="en">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_message_language"
            data-xp-lang="en"
            class="radio-group-input"
            :class="{ checked }"
          >
            {{ t('fields.languages.options.en') }}
          </li>
        </RadioGroupOption>
        <RadioGroupOption v-slot="{ checked }" as="template" value="fr">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_message_language"
            data-xp-lang="fr"
            class="radio-group-input"
            :class="{ checked }"
          >
            {{ t('fields.languages.options.fr') }}
          </li>
        </RadioGroupOption>
      </ul>
    </RadioGroup>

    <div class="field-input mt-14">
      <label for="message">
        {{ t('fields.message.label') }}
      </label>
      <textarea
        id="message"
        v-model="message"
        name="message"
        :data-xp-lang="language"
        class="textarea-input ph-capture"
        rows="6"
      />
    </div>

    <div v-if="$slots.default" class="mt-4">
      <slot />
    </div>

    <template #footer>
      <button
        type="button"
        data-x-action="send"
        data-x-name="pro_share_submit"
        :disabled="loading"
        class="w-full mb-16 button icon-right blue md:mb-0"
        @click="emit('send')"
      >
        <span>{{ t('send') }}</span>
        <IconNavigationSend class="flex-none w-5 h-5" />
      </button>
    </template>
  </FeaturesSharesStep>
</template>

<i18n lang="json">
{
  "en": {
    "fields": {
      "languages": {
        "label": "What language do you use with your patient?",
        "options": {
          "en": "English",
          "fr": "French"
        }
      },
      "message": {
        "label": "Message"
      }
    },
    "send": "Send"
  },
  "fr": {
    "fields": {
      "languages": {
        "label": "Quelle langue utilisez-vous avec votre patient?",
        "options": {
          "en": "Anglais",
          "fr": "Français"
        }
      },
      "message": {
        "label": "Message"
      }
    },
    "send": "Envoyer"
  }
}
</i18n>
