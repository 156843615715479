<script setup lang="ts">
import type { Ref } from 'vue'

defineProps<{
  hideStep?: boolean
}>()

const emit = defineEmits<{
  (event: 'goto', value: number): void
}>()

const step = inject<Ref<number>>('step')
const stepMax = inject<number>('stepMax')
</script>

<template>
  <div class="flex flex-col flex-1 md:pb-10">
    <div class="w-full md:max-w-xl md:mx-auto" :class="{ 'h-full': hideStep }">
      <slot />
    </div>

    <div class="flex flex-col-reverse w-full pt-10 mt-auto md:items-center md:justify-between md:flex-row md:max-w-xl md:mx-auto">
      <ul v-if="step !== undefined && !hideStep" class="flex gap-3 mx-auto md:mx-0">
        <li
          v-for="n in stepMax"
          :key="`step-${n}`"
          :aria-label="`Goto step ${n}`"
          tabindex="0"
          x-click
          data-x-action="goto"
          data-x-name="goto_step"
          :data-xp-step="n - 1"
          class="w-2 h-2 rounded-full"
          :class="{
            'cursor-pointer': n - 1 < step,
            'pointer-events-none': n - 1 >= step,
            'bg-blue-500': n - 1 === step,
            'bg-gray-400': n - 1 < step,
            'bg-gray-300 pointer-events-none': n - 1 > step,
          }"
          @click="emit('goto', n - 1)"
        />
      </ul>
      <div class="min-h-[2.5rem]" :class="{ 'w-full': hideStep }">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
