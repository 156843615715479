<script setup lang="ts">
import type { Ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const props = defineProps<{
  displayName: string
}>()

const emit = defineEmits<{
  (event: 'update:displayName', value: string): void
  (event: 'next'): void
  (event: 'goto', value: number): void
}>()

const display_name = useVModel(props, 'displayName', emit)

const { t } = useI18n({ useScope: 'local' })
const me = useProfile()

const title_and_name = computed(() => {
  if (me.value)
    return `${me.value.first_name} ${me.value.last_name}, ${t(`titles.${me.value.title}`)}`

  return 'undefined'
})

const title = computed(() => {
  if (me.value)
    return useCapitalizeFirstLetter(t(`titles.${me.value.title}`))

  return 'undefined'
})

const app = inject<Ref<AppGuide.ApplicationFull | AppGuide.Application>>('application')
const { track } = useAnalytic()
function handle_next(option: string) {
  track('select-displayName-share', {
    displayName: option,
    uid: app?.value?.uid,
  })
  emit('next')
}
</script>

<template>
  <FeaturesSharesStep @goto="emit('goto', $event)">
    <RadioGroup v-model="display_name" class="flex flex-col">
      <RadioGroupLabel class="mb-4 text-xl font-bold text-center">
        {{ t('title') }}
      </RadioGroupLabel>

      <ul class="grid grid-cols-1 gap-2">
        <RadioGroupOption as="template" :value="title_and_name">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_display_name"
            data-xp-display="title_and_name"
            class="flex flex-col items-center justify-center radio-group-input"
            @click="handle_next('title_and_name')"
          >
            <span>{{ title_and_name }}</span>
            <span class="text-sm italic font-normal text-gray-500">{{ t('name_and_title') }}</span>
          </li>
        </RadioGroupOption>
        <RadioGroupOption as="template" :value="title">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_display_name"
            data-xp-display="title"
            class="flex flex-col items-center justify-center radio-group-input"
            @click="handle_next('title')"
          >
            <span>{{ title }}</span>
            <span class="text-sm italic font-normal text-gray-500">{{ t('only_title') }}</span>
          </li>
        </RadioGroupOption>
      </ul>
    </RadioGroup>
  </FeaturesSharesStep>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Which name would you like to display?",
    "name_and_title": "Name & title",
    "only_title": "Title",
    "titles": {
      "administrator": "administrator",
      "audiologist": "audiologist",
      "auxiliary-nurse": "auxiliary nurse",
      "chiropractor": "chiropractor",
      "clinical-nurse": "clinical nurse",
      "dental-hygienist": "dental hygienist",
      "dentist": "dentist",
      "dietitian": "dietitian",
      "ergotherapist": "ergotherapist",
      "general-practitioner": "general practitioner",
      "inhalotherapist": "inhalotherapist",
      "kinesiologist": "kinesiologist",
      "midwive": "midwive",
      "nurse": "nurse",
      "optometrist": "optometrist",
      "pharmacist": "pharmacist",
      "pharmacy-technical-assistant": "pharmacy technical assistant",
      "physiotherapist": "physiotherapist",
      "physiotherapy-technologists": "physiotherapy technologists",
      "podiatrist": "podiatrist",
      "psychoeducator": "psychoeducator",
      "psychologist": "psychologist",
      "psychotherapist": "psychotherapist",
      "sex-therapist": "sex therapist",
      "social-worker": "social worker",
      "specialized-nurse-practitioner": "specialized nurse practitioner",
      "speech-therapist": "speech therapist",
      "veterinarian": "veterinarian",
      "specialist-physician": "specialist physician",
      "nutritionist": "nutritionist"
    }
  },
  "fr": {
    "title": "Comment souhaitez-vous que votre nom soit affiché ?",
    "name_and_title": "Nom & titre",
    "only_title": "Titre",
    "titles": {
      "administrator": "administrateur",
      "audiologist": "audiologiste",
      "auxiliary-nurse": "infirmière auxiliaire",
      "chiropractor": "chiropracteur",
      "clinical-nurse": "infirmière clinicienne",
      "dental-hygienist": "hygiéniste dentaire",
      "dentist": "dentiste",
      "dietitian": "diététicien",
      "ergotherapist": "ergothérapeute",
      "general-practitioner": "médecin généraliste",
      "inhalotherapist": "inhalothérapeute",
      "kinesiologist": "kinésiologue",
      "midwive": "sage-femme",
      "nurse": "infirmière",
      "optometrist": "optométriste",
      "pharmacist": "pharmacien",
      "pharmacy-technical-assistant": "assistant technique en pharmacie",
      "physiotherapist": "physiothérapeute",
      "physiotherapy-technologists": "technologues en physiothérapie",
      "podiatrist": "podologue",
      "psychoeducator": "psychoéducateur",
      "psychologist": "psychologue",
      "psychotherapist": "psychothérapeute",
      "sex-therapist": "sexologue",
      "social-worker": "travailleur social",
      "specialized-nurse-practitioner": "infirmière praticienne spécialisée",
      "speech-therapist": "orthophoniste",
      "veterinarian": "vétérinaire",
      "specialist-physician": "médecin spécialiste",
      "nutritionist": "nutritionniste"
    }
  }
}
</i18n>
