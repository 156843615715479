<script setup lang="ts">
const props = defineProps<{
  application: AppGuide.Application | AppGuide.ApplicationFull
  view: 'app-card' | 'app-page' | 'app-card-sm'
}>()

provide('application', props.application)

const { t } = useI18n({ useScope: 'local' })

const bodyElement = ref<HTMLBodyElement | null>(null)
const isLocked = useScrollLock(bodyElement)
onMounted(() => {
  bodyElement.value = document.querySelector('body')
})

const me = useProfile()
const open = ref(false)

const { track } = useAnalytic()

function handle_open() {
  open.value = true
  isLocked.value = true

  track('open-share', {
    uid: props.application.uid,
    source: props.view,
  })
}

function handle_close(tracking_data: any) {
  track('close-share', {
    uid: props.application.uid,
    ...tracking_data,
  })

  open.value = false
  isLocked.value = false
}
</script>

<template>
  <button
    v-if="me"
    type="button"
    data-x-action="open"
    data-x-name="pro_share"
    :aria-label="t('share')"
    class="flex items-center px-4 font-bold transition-colors border-2 border-gray-300 rounded-full ph-no-capture group hover:text-white hover:border-blue-500 hover:bg-blue-500"
    :class="{
      'min-w-[200px] justify-center bg-white h-[60px]': view === 'app-page',
      'py-1.5 w-full justify-center': view === 'app-card-sm',
      'py-1.5 relative z-10 app-card': view === 'app-card',
    }"
    @click="handle_open"
  >
    <IconNavigationSend
      class="w-5 h-5"
      :class="{
        'mr-2': view === 'app-card-sm' || view === 'app-page',
      }"
    />
    <span v-if="view === 'app-card-sm' || view === 'app-page'">{{ t('share') }}</span>
  </button>

  <Teleport to="body">
    <FeaturesSharesModal
      v-if="open"
      @close="handle_close($event)"
    />
  </Teleport>
</template>

<style scoped lang="scss">
.app-card {
  span {
    @apply text-gray-500;
  }

  &:hover {
    span {
      @apply text-white;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "share": "Send To"
  },
  "fr": {
    "share": "Envoyer à"
  }
}
</i18n>
