<script setup lang="ts">
const props = defineProps<{
  phone: string
  secondPhone: string
  valide: boolean | null
}>()

const emit = defineEmits<{
  'update:phone': [value: string]
  'update:secondPhone': [value: string]
  'update:valide': [isValide: boolean]
}>()

const { t } = useI18n({ useScope: 'local' })

const phone = useVModel(props, 'phone', emit)
const phoneValide = ref<boolean | null>(null)

const secondPhone = useVModel(props, 'secondPhone', emit)
const secondPhoneValide = ref<boolean | null>(null)

const addOneMore = ref(false)
function handleRemoveSecondPhone() {
  addOneMore.value = false
  secondPhone.value = ''
  secondPhoneValide.value = null
}

watch([phone, secondPhone], async ([, secondPhone]) => {
  await nextTick()

  let isValide = false
  if (secondPhone === '')
    isValide = !!phoneValide.value
  else
    isValide = !!phoneValide.value && !!secondPhoneValide.value

  emit('update:valide', isValide)
})
</script>

<template>
  <div>
    <div class="field-input">
      <label for="recipient-sms" class="text-center">
        {{ t('sms.label') }}
      </label>
      <FormInputPhone
        v-model="phone"
        name="recipient-sms"
        :placeholder="t('sms.placeholder')"
        @validation="phoneValide = $event"
      />
      <p v-if="phoneValide === false" class="error-message">
        <IconAttributesBooleanCross class="w-4 h-4 ml-4 mr-1" />
        <span>{{ t('sms.error.phone') }}</span>
      </p>
    </div>
    <div v-if="addOneMore" class="mt-4 field-input">
      <div class="flex gap-2">
        <FormInputPhone
          v-model="secondPhone"
          name="second-recipient-sms"
          :placeholder="t('secondSms.placeholder')"
          class="flex-grow flex-shrink"
          @validation="secondPhoneValide = $event"
        />
        <button
          type="button"
          data-x-action="remove"
          data-x-name="second-recipient-sms"
          aria-label="remove second phone number"
          class="w-[3.625rem] h-[3.625rem] flex items-center justify-center flex-none text-gray-500 border border-gray-300 rounded-xl transition-colors hover:border-blue-500 hover:bg-blue-500 hover:text-white"
          @click="handleRemoveSecondPhone"
        >
          <IconNavigationTrash class="w-6 h-6" />
        </button>
      </div>
      <p v-if="secondPhoneValide === false" class="error-message">
        <IconAttributesBooleanCross class="w-4 h-4 ml-4 mr-1" />
        <span>{{ t('sms.error.phone') }}</span>
      </p>
    </div>
    <button
      v-else
      data-x-action="add"
      data-x-name="second-recipient-sms"
      class="w-full mt-4 font-bold text-center text-blue-500 transition-colors bg-blue-200 border border-blue-300 border-dashed rounded-full h-11 hover:border-blue-500 hover:bg-blue-500 hover:text-white"
      @click="addOneMore = true"
    >
      {{ t('add-one-more') }}
    </button>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "sms": {
      "label": "Recipient",
      "placeholder": "Recipient phone number",
      "error": {
        "phone": "Invalid phone number"
      }
    },
    "secondSms": {
      "placeholder": "Second recipient phone number"
    },
    "add-one-more": "Add a recipient"
  },
  "fr": {
    "sms": {
      "label": "Destinataire",
      "placeholder": "Numéro de téléphone du destinataire",
      "error": {
        "phone": "Numéro de téléphone invalide"
      }
    },
    "secondSms": {
      "placeholder": "Numéro de téléphone du second destinataire"
    },
    "add-one-more": "Ajouter un destinataire"
  }
}
</i18n>
