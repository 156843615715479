<script setup lang="ts">
import type { Ref } from 'vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const props = defineProps<{
  sentBy: 'email' | 'sms' | string
  email: string
  secondEmail: string
  phone: string
  secondPhone: string
}>()

const emit = defineEmits<{
  (event: 'update:sentBy', value: 'email' | 'sms'): void
  (event: 'update:email', value: string): void
  (event: 'update:secondEmail', value: string): void
  (event: 'update:phone', value: string): void
  (event: 'update:secondPhone', value: string): void
  (event: 'next'): void
  (event: 'goto', value: number): void
}>()

const app = inject<Ref<AppGuide.ApplicationFull | AppGuide.Application>>('application')
const { t } = useI18n({ useScope: 'local' })
const { track } = useAnalytic()

const sentBy = useVModel(props, 'sentBy', emit)
watch(sentBy, (value) => {
  track('select-sentBy-share', {
    uid: app?.value?.uid,
    sentBy: value,
  })
})

const email = useVModel(props, 'email', emit)
const secondEmail = useVModel(props, 'secondEmail', emit)
const sentByEmailValide = ref<boolean | null>(null)

const phone = useVModel(props, 'phone', emit)
const secondPhone = useVModel(props, 'secondPhone', emit)
const sentBySmsValide = ref<boolean | null>(null)

const canContinue = computed(() => {
  if (sentBy.value === 'email')
    return !!sentByEmailValide.value

  if (sentBy.value === 'sms')
    return !!sentBySmsValide.value

  return false
})
</script>

<template>
  <FeaturesSharesStep>
    <div v-if="app" class="flex items-center p-3 bg-white rounded-xl shadow-fields">
      <img :src="app.icon" width="44" height="44" class="rounded-lg w-11 h-11">
      <div class="pl-3">
        <p class="font-bold line-clamp-1">
          {{ app.title }}
        </p>
        <p class="flex items-center space-x-1 text-sm font-semibold text-gray-500">
          <span class="overflow-hidden line-clamp-1">{{ app.developer_name }}</span>
        </p>
      </div>
    </div>

    <RadioGroup v-model="sentBy" class="flex flex-col mt-12">
      <RadioGroupLabel class="mb-4 text-xl font-bold text-center">
        {{ t('sent_by.label') }}
      </RadioGroupLabel>
      <ul class="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <RadioGroupOption v-slot="{ checked }" as="template" value="sms">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_methods"
            data-xp-method="sms"
            class="radio-group-input"
            :class="{ checked }"
          >
            <IconPermissions
              platform="iOS"
              permission="SMS"
              class="flex w-6 h-6 mr-2"
            />
            <span>{{ t('sent_by.options.sms') }}</span>
          </li>
        </RadioGroupOption>
        <RadioGroupOption v-slot="{ checked }" as="template" value="email">
          <li
            x-click
            data-x-action="select"
            data-x-name="pro_share_methods"
            data-xp-method="email"
            class="radio-group-input"
            :class="{ checked }"
          >
            <IconNavigationEmail class="w-6 h-6 mr-2" />
            <span>{{ t('sent_by.options.email') }}</span>
          </li>
        </RadioGroupOption>
      </ul>
    </RadioGroup>

    <div class="mt-6 mb-2 text-xs leading-5 text-center">
      <p>{{ t('sent_by.description[0]') }}</p>
      <p>{{ t('sent_by.description[1]') }}</p>
    </div>

    <FeaturesSharesInputEmail
      v-show="sentBy === 'email'"
      v-model:email="email"
      v-model:secondEmail="secondEmail"
      v-model:valide="sentByEmailValide"
      class="mt-4"
    />

    <FeaturesSharesInputPhone
      v-show="sentBy === 'sms'"
      v-model:phone="phone"
      v-model:secondPhone="secondPhone"
      v-model:valide="sentBySmsValide"
      class="mt-4"
    />

    <template #footer>
      <button
        type="button"
        data-x-action="goto"
        data-x-name="next"
        class="w-full mb-16 button blue md:mb-0"
        :disabled="!canContinue"
        @click="emit('next')"
      >
        {{ t('next') }}
      </button>
    </template>
  </FeaturesSharesStep>
</template>

<i18n lang="json">
{
  "en": {
    "sent_by": {
      "label": "Which method would you like to use?",
      "options": {
        "sms": "SMS",
        "email": "Email"
      },
      "description": [
        "By choosing a sharing method, you accept AppGuide's terms and conditions.",
        "AppGuide will never use this phone number or email for any other purposes."
      ]
    },
    "email": {
      "label": "Recipient email",
      "error": {
        "email": "Must be a valid email"
      }
    },
    "sms": {
      "label": "Recipient phone number",
      "error": {
        "phone": "Invalid phone number"
      }
    },
    "next": "Next step"
  },
  "fr": {
    "sent_by": {
      "label": "Quelle méthode souhaitez-vous utiliser?",
      "options": {
        "sms": "SMS",
        "email": "Courriel"
      },
      "description": [
        "En choisissant un mode de partage, vous acceptez les conditions générales d'utilisation d'AppGuide.",
        "AppGuide n'utilisera jamais ce numéro de téléphone ou cette adresse courriel à d'autres fins."
      ]
    },
    "email": {
      "label": "Adresse courriel du destinataire",
      "error": {
        "email": "Doit être une adresse courriel valide"
      }
    },
    "sms": {
      "label": "Numéro de téléphone du destinataire",
      "error": {
        "phone": "Numéro de téléphone invalide"
      }
    },
    "next": "Étape suivante"
  }
}
</i18n>
