<script setup lang="ts">
defineProps<{
  sentBy: string
  email?: string
  phone?: string
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const { t } = useI18n({ useScope: 'local' })
</script>

<template>
  <FeaturesSharesStep hide-step>
    <div class="flex flex-col items-center h-full text-center md:justify-center">
      <img
        v-if="sentBy === 'email'"
        src="/assets/illustrations/mascot-sent-email.svg"
        alt="AppGuide mascot as sent your email"
        width="183"
        height="142"
        class="mt-10 mb-10"
      >
      <img
        v-else-if="sentBy === 'sms'"
        src="/assets/illustrations/mascot-sent-sms.svg"
        alt="AppGuide mascot as sent your SMS"
        width="190"
        height="142"
        class="mt-10 mb-10 md:mt-auto"
      >

      <p class="text-xl font-bold font-display">
        {{ t('title') }}
      </p>

      <div v-if="sentBy === 'email'" class="mt-5 text-sm text-gray-500">
        <p>{{ t('sent_by.email[0]') }} <strong class="font-semibold">{{ email }}</strong></p>
        <p>{{ t('sent_by.email[1]') }} <strong class="font-semibold">support@appguide.ca</strong></p>
      </div>
      <div v-else-if="sentBy === 'sms'" class="mt-5 text-sm text-gray-500">
        <p>{{ t('sent_by.sms[0]') }} <strong class="font-semibold">{{ phone }}</strong></p>
        <p>{{ t('sent_by.sms[1]') }}</p>
      </div>

      <p class="mt-6 mb-6 text-sm text-gray-500">
        {{ t('science-facts') }}
        (<NuxtLink to="https://www.jmir.org/2019/8/e13609/" target="_blank" class="underline">
          {{ t('science-facts-ref') }}
        </NuxtLink>).
      </p>
    </div>

    <template #footer>
      <button
        data-x-action="close"
        data-x-name="pro_share_close"
        type="button"
        class="mx-auto button blue"
        @click="emit('close')"
      >
        {{ t('close') }}
      </button>
    </template>
  </FeaturesSharesStep>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Thank you for using AppGuide to share information to your patient",
    "sent_by": {
      "email": [
        "In the next few minutes, the email ",
        "should receive your message from "
      ],
      "sms": [
        "In the next few minutes, the phone number ",
        "should receive your message."
      ]
    },
    "science-facts": "Congratulations, your patient has 10 times more chance to use the health app thanks to your electronic share",
    "science-facts-ref": "Mohr and al., 2019",
    "close": "Close"
  },
  "fr": {
    "title": "Merci d'avoir utilisé AppGuide pour partager des informations à votre patient",
    "sent_by": {
      "email": [
        "Dans les prochaines minutes, l'adresse courriel ",
        "devrait recevoir votre message de "
      ],
      "sms": [
        "Dans les prochaines minutes, le numéro de téléphone ",
        "devrait recevoir votre message."
      ]
    },
    "science-facts": "Félicitations, votre patient a 10 fois plus de chance d'utiliser l'application de santé grâce à votre partage électronique",
    "science-facts-ref": "Mohr et al., 2019",
    "close": "Fermer"
  }
}
</i18n>
