<script setup lang="ts">
import { VueTelInput } from 'vue-tel-input'

const props = defineProps<{
  modelValue: string
  name: string
  placeholder?: string
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
  (event: 'validation', value: boolean): void
}>()

const value = useVModel(props, 'modelValue', emit)
const has_blur = ref(false)
const is_valid = ref(false)

function handle_validation(e: any) {
  is_valid.value = e.valid

  if (is_valid.value)
    emit('validation', is_valid.value)

  else if (!is_valid.value && has_blur.value)
    emit('validation', is_valid.value)
}

const inputOptions = computed(() => ({
  name: props.name,
  id: props.name,
  type: 'tel',
  placeholder: props.placeholder,
}))

watch(has_blur, () => {
  emit('validation', is_valid.value)
})
</script>

<template>
  <div>
    <Html>
      <Head>
        <link rel="stylesheet" href="https://unpkg.com/vue-tel-input/dist/vue-tel-input.css">
      </Head>
    </Html>

    <div>
      <VueTelInput
        v-model="value"
        mode="international"
        class="input-phone"
        :input-options="inputOptions"
        @validate="handle_validation"
        @blur="has_blur = true"
      />
    </div>
  </div>
</template>

<style lang="scss">
.vue-tel-input.input-phone {
  @apply rounded-xl border-gray-200 shadow-fields;

  &:focus-within {
    @apply border-gray-200 shadow-fields;
  }

  .vti__dropdown {
    @apply rounded-l-xl bg-white;

    &-list {
      &.below {
        // top: 3.625rem;
        top: -200px;
      }
    }

    &-item {
      @apply flex items-center pl-2 pr-3 py-2;

      .vti__flag {
        @apply flex-none;
      }

      span:not(.vti__flag) {
        @apply flex-none ml-auto pl-2;
      }

      strong {
        @apply flex text-sm flex-shrink flex-grow pl-3;
      }
    }
  }

  input[type="tel"] {
    @apply py-4 pl-3 rounded-r-xl;
  }
}
</style>
